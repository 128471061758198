import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { FC, useEffect, useState } from "react";
import Button from "~/components/Button";
import Trans from "~/types/translationKeys";
import styles from "./CountdownBanner.module.scss";

interface TimeLeft {
  hours: number;
  minutes: number;
  seconds: number;
}

const CountdownBanner: FC = () => {
  const { t } = useTranslation();

  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = () => {
    const targetDate = "9-20-2024";

    const difference = new Date(targetDate).getTime() - new Date().getTime();
    let timeLeft: TimeLeft = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <div className={styles.wrapper}>
      <div className="flex flex-col gap-[20px] items-center lg:items-start flex-1 px-[24px] py-[24px] lg:px-[48px] lg:py-[54px]">
        <p className={styles.text}>
          {t(Trans.dont_miss_this_exclusive_limited_time_deal)}
        </p>
        <div className="flex gap-[24px] items-center flex-col sm:flex-row">
          {/* <div className="flex items-center gap-2">
            <div className={styles.number_wrapper}>
              <Text type={"headline-4"} color={"white"}>
                {timeLeft.hours.toString().padStart(2, "0")}
              </Text>
            </div>
            <Text type={"headline-4"} color={"white"}>
              :
            </Text>
            <div className={styles.number_wrapper}>
              <Text type={"headline-4"} color={"white"}>
                {timeLeft.minutes.toString().padStart(2, "0")}
              </Text>
            </div>
            <Text type={"headline-4"} color={"white"}>
              :
            </Text>
            <div className={styles.number_wrapper}>
              <Text type={"headline-4"} color={"white"}>
                {timeLeft.seconds.toString().padStart(2, "0")}
              </Text>
            </div>
          </div> */}
          <Link href={"/life-time-deals"}>
            <Button variant="white">{t(Trans.grab_lifetime_deal_now)}</Button>
          </Link>
        </div>
      </div>
      <div className="relative flex-1 aspect-[563/220]">
        <Image
          src={"/svgs/lifetime_countdown_banner.svg"}
          alt="Lifetime countdown banner"
          fill
          className="object-contain object-bottom"
        />
      </div>
    </div>
  );
};

export default CountdownBanner;
